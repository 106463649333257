// Fix foundation Grid for IE 8.

/* The Grid ---------------------- */
.row {
  width: 1000px;
  max-width: 100%;
  min-width: 768px;
  margin: 0 auto;
}

.row .row {
  width: auto;
  max-width: none;
  min-width: 0;
  margin: 0 -15px;
}

.row.large-collapse .column,
.row.large-collapse .columns {
  padding: 0;
}

.row .row {
  width: auto;
  max-width: none;
  min-width: 0;
  margin: 0 -15px;
}

.row .row.large-collapse {
  margin: 0;
}

.column, .columns {
  float: left;
  min-height: 1px;
  padding: 0 15px;
  position: relative;
}

.column.large-centered, .columns.large-centered {
  float: none;
  margin: 0 auto;
}

[class*="column"] + [class*="column"]:last-child {
  float: right;
}

[class*="column"] + [class*="column"].end {
  float: left;
}

.pull-2 {
  right: 16.66667%;
}

.pull-3 {
  right: 25%;
}

.pull-4 {
  right: 33.33333%;
}

.pull-5 {
  right: 41.66667%;
}

.pull-6 {
  right: 50%;
}

.pull-7 {
  right: 58.33333%;
}

.pull-8 {
  right: 66.66667%;
}

.pull-9 {
  right: 75%;
}

.pull-10 {
  right: 83.33333%;
}

.push-2 {
  left: 16.66667%;
}

.push-3 {
  left: 25%;
}

.push-4 {
  left: 33.33333%;
}

.push-5 {
  left: 41.66667%;
}

.push-6 {
  left: 50%;
}

.push-7 {
  left: 58.33333%;
}

.push-8 {
  left: 66.66667%;
}

.push-9 {
  left: 75%;
}

.push-10 {
  left: 83.33333%;
}

/* Nicolas Gallagher's micro clearfix */
.row {
  *zoom: 1;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.small-1, .row .small-1 {
  width: 8.33333%;
}

.small-2, .row .small-2 {
  width: 16.66667%;
}

.small-3, .row .small-3 {
  width: 25%;
}

.small-4, .row .small-4 {
  width: 33.33333%;
}

.small-5, .row .small-5 {
  width: 41.66667%;
}

.small-6, .row .small-6 {
  width: 50%;
}

.small-7, .row .small-7 {
  width: 58.33333%;
}

.small-8, .row .small-8 {
  width: 66.66667%;
}

.small-9, .row .small-9 {
  width: 75%;
}

.small-10, .row .small-10 {
  width: 83.33333%;
}

.small-11, .row .small-11 {
  width: 91.66667%;
}

.small-12, .row .small-12 {
  width: 100%;
}

.large-1, .row .large-1 {
  width: 8.33333%;
}

.large-2, .row .large-2 {
  width: 16.66667%;
}

.large-3, .row .large-3 {
  width: 25%;
}

.large-4, .row .large-4 {
  width: 33.33333%;
}

.large-5, .row .large-5 {
  width: 41.66667%;
}

.large-6, .row .large-6 {
  width: 50%;
}

.large-7, .row .large-7 {
  width: 58.33333%;
}

.large-8, .row .large-8 {
  width: 66.66667%;
}

.large-9, .row .large-9 {
  width: 75%;
}

.large-10, .row .large-10 {
  width: 83.33333%;
}

.large-11, .row .large-11 {
  width: 91.66667%;
}

.large-12, .row .large-12 {
  width: 100%;
}

.row .small-offset-1 {
  margin-left: 8.33333%;
}

.row .small-offset-2 {
  margin-left: 16.66667%;
}

.row .small-offset-3 {
  margin-left: 25%;
}

.row .small-offset-4 {
  margin-left: 33.33333%;
}

.row .small-offset-5 {
  margin-left: 41.66667%;
}

.row .small-offset-6 {
  margin-left: 50%;
}

.row .small-offset-7 {
  margin-left: 58.33333%;
}

.row .small-offset-8 {
  margin-left: 66.66667%;
}

.row .small-offset-9 {
  margin-left: 75%;
}

.row .small-offset-10 {
  margin-left: 83.33333%;
}

.row .large-offset-1 {
  margin-left: 8.33333%;
}

.row .large-offset-2 {
  margin-left: 16.66667%;
}

.row .large-offset-3 {
  margin-left: 25%;
}

.row .large-offset-4 {
  margin-left: 33.33333%;
}

.row .large-offset-5 {
  margin-left: 41.66667%;
}

.row .large-offset-6 {
  margin-left: 50%;
}

.row .large-offset-7 {
  margin-left: 58.33333%;
}

.row .large-offset-8 {
  margin-left: 66.66667%;
}

.row .large-offset-9 {
  margin-left: 75%;
}

.row .large-offset-10 {
  margin-left: 83.33333%;
}

.show-for-small {
  display: none !important;
}

.hide-for-small,
.front .block-views-exp-vacation-rentals-list {
  display: inherit !important;
}

.hide-for-medium,
.hide-for-medium-up {
  display: none !important;
}

table.show-for-medium,
table.show-for-medium-up,
table.hide-for-small,
.front table.block-views-exp-vacation-rentals-list {
  display: table;
}

thead.show-for-medium,
thead.show-for-medium-up,
thead.hide-for-small,
.front thead.block-views-exp-vacation-rentals-list {
  display: table-header-group !important;
}

tbody.show-for-medium,
tbody.show-for-medium-up,
tbody.hide-for-small,
.front tbody.block-views-exp-vacation-rentals-list {
  display: table-row-group !important;
}

tr.show-for-medium,
tr.show-for-medium-up,
tr.hide-for-small,
.front tr.block-views-exp-vacation-rentals-list {
  display: table-row !important;
}

td.show-for-medium,
td.show-for-medium-up,
td.hide-for-small,
.front td.block-views-exp-vacation-rentals-list,
th.show-for-medium,
th.show-for-medium-up,
th.hide-for-small,
.front th.block-views-exp-vacation-rentals-list {
  display: table-cell !important;
}

.show-for-large,
.show-for-large-up {
  display: inherit !important;
}

.show-for-medium,
.show-for-medium-down {
  display: none !important;
}

.hide-for-medium,
.hide-for-medium-down {
  display: inherit !important;
}

table.show-for-large,
table.show-for-large-up,
table.hide-for-medium,
table.hide-for-medium-down {
  display: table;
}

thead.show-for-large,
thead.show-for-large-up,
thead.hide-for-medium,
thead.hide-for-medium-down {
  display: table-header-group !important;
}

tbody.show-for-large,
tbody.show-for-large-up,
tbody.hide-for-medium,
tbody.hide-for-medium-down {
  display: table-row-group !important;
}

tr.show-for-large,
tr.show-for-large-up,
tr.hide-for-medium,
tr.hide-for-medium-down {
  display: table-row !important;
}

td.show-for-large,
td.show-for-large-up,
td.hide-for-medium,
td.hide-for-medium-down,
th.show-for-large,
th.show-for-large-up,
th.hide-for-medium,
th.hide-for-medium-down {
  display: table-cell !important;
}

.show-for-xlarge {
  display: inherit !important;
}

.hide-for-large,
.hide-for-large-down {
  display: inherit !important;
}

table.show-for-xlarge,
table.hide-for-large,
table.hide-for-large-down {
  display: table;
}

thead.show-for-xlarge,
thead.hide-for-large,
thead.hide-for-large-down {
  display: table-header-group !important;
}

tbody.show-for-xlarge,
tbody.hide-for-large,
tbody.hide-for-large-down {
  display: table-row-group !important;
}

tr.show-for-xlarge,
tr.hide-for-large,
tr.hide-for-large-down {
  display: table-row !important;
}

td.show-for-xlarge, td.hide-for-large, td.hide-for-large-down,
th.show-for-xlarge,
th.hide-for-large,
th.hide-for-large-down {
  display: table-cell !important;
}

// Place all your other random bits and bobs for IE8 and below in this partial.
// They will automatically get included into the IE-specific stylesheet, along
// with the Foundation 4 grid fix.

// Tobar fix
// Based on https://gist.github.com/tmayr/5190565
// Color should be set already by theme/top-bar.scss

.lt-ie9 .top-bar {
  //  background: $topbar-bg;
  *zoom: 1;
  overflow: visible;
}

.lt-ie9 .top-bar:before, .lt-ie9 .top-bar:after {
  content: " ";
  display: table;
}

.lt-ie9 .top-bar:after {
  clear: both;
}

.lt-ie9 .top-bar .toggle-topbar {
  display: none;
}

.lt-ie9 .top-bar .title-area {
  float: left;
}

.lt-ie9 .top-bar .name h1 a {
  width: auto;
}

.lt-ie9 .top-bar input,
.lt-ie9 .top-bar .button {
  line-height: 2em;
  font-size: 0.875em;
  height: 2em;
  padding: 0 10px;
  position: relative;
  top: 8px;
}

.lt-ie9 .top-bar.expanded {
  //  background: #111111;
}

.lt-ie9 .contain-to-grid .top-bar {
  max-width: 57.5em;
  margin: 0 auto;
}

.lt-ie9 .top-bar-section {
  -webkit-transition: none 0 0;
  -moz-transition: none 0 0;
  transition: none 0 0;
  left: 0 !important;
}

.lt-ie9 .top-bar-section ul {
  width: auto;
  height: auto !important;
  display: inline;
}

.lt-ie9 .top-bar-section ul li {
  float: left;
}

.lt-ie9 .top-bar-section ul li > a {
  padding-right: 14px !important;
}

.lt-ie9 .top-bar-section ul li .js-generated {
  display: none;
}

.lt-ie9 .top-bar-section li a:not(.button) {
  padding: 0 15px;
  line-height: 45px;
  //  background: #111111;
}

.lt-ie9 .top-bar-section li a:not(.button):hover {
  //  background: #2b2b2b;
}

.lt-ie9 .top-bar-section .has-dropdown > a {
  padding-right: 35px !important;
}

.lt-ie9 .top-bar-section .has-dropdown > a:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  //  border: solid 5px;
  //  border-color: rgba(255, 255, 255, 0.5) $transparent $transparent $transparent;
  margin-top: -2.5px;
}

.lt-ie9 .top-bar-section .has-dropdown.moved {
  position: relative;
}

.lt-ie9 .top-bar-section .has-dropdown.moved > .dropdown {
  visibility: hidden;
}

.lt-ie9 .top-bar-section .has-dropdown:hover > .dropdown,
.lt-ie9 .top-bar-section .has-dropdown:active > .dropdown {
  visibility: visible;
}

.lt-ie9 .top-bar-section .has-dropdown .dropdown li.has-dropdown > a:after {
  border: none;
  content: "\00bb";
  margin-top: -7px;
  right: 5px;
}

.lt-ie9 .top-bar-section .dropdown {
  left: 0;
  top: auto;
  //  background: $transparent;
}

.lt-ie9 .top-bar-section .dropdown li a {
  line-height: 1;
  white-space: nowrap;
  padding: 7px 15px;
  //  background: #1e1e1e;
}

.lt-ie9 .top-bar-section .dropdown li label {
  white-space: nowrap;
  //  background: #1e1e1e;
}

.lt-ie9 .top-bar-section .dropdown li .dropdown {
  left: 100%;
  top: 0;
}

.lt-ie9 .top-bar-section > ul > .divider {
  border-bottom: none;
  border-top: none;
  //  border-right: solid 1px #2b2b2b;
  //  border-left: solid 1px black;
  clear: none;
  height: 45px;
  width: 0px;
}

.lt-ie9 .top-bar-section .has-form {
  //  background: #111111;
  padding: 0 15px;
  height: 45px;
}

.lt-ie9 .top-bar-section ul.right li .dropdown {
  left: auto;
  right: 0;
}

.lt-ie9 .top-bar-section ul.right li .dropdown li .dropdown {
  right: 100%;
}

//.top-bar {
//  .has-dropdown {
//
//    .dropdown {
//      display: none;
//
//    }
//
//    &:hover .dropdown {
//     display: block;
//    }
//  }
//}
