// Custom styles for Internet Explorer 8.
// Main color variables.
@import "vrweb_settings";
// Fix Foundation Grid.
@import "base/ie";

body {
  //HEADER STYLES
  .l-header {
    padding-top: 0px;
    padding-bottom: 7px;
    background: url("../images/header-shadow.png") left bottom repeat-x;

    > .header-content {
      background: #fff;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  //TOPBAR OVERRIDES
  .top-bar-section ul.right {
    background-color: transparent;
    background: none;

    li, li a {
      background-color: transparent;
      background: none;
    }
    li a:hover, li a:active {
      color: $tertiary-color;
    }
    li a,
    li.has-dropdown a {
      padding-left: 14px !important;
      padding-right: 14px !important;
    }
  }
  .top-bar-container .top-bar .main-nav > li.has-dropdown .dropdown li.show-for-small {
    display: block !important;
  }
  .top-bar-container .top-bar .main-nav > li.has-dropdown > a:after {
    display: none;
  }
  //SERVICE BAR
  .l-service-bar {
    background: none;

    ul.inline-list a {
      padding: 0px;
      height: 35px;
      line-height: 35px;

      &.phone {
        background-color: transparent;
        background: none;

        &:hover, &:active {
          background-color: transparent;
          background: none;
        }
      }
    }
  }

  //FORM COMPONENTS NEED SET HEIGHT, DOES NOT RECOGNIZE HTML5 FORM INPUTS
  input[type='text'],
  select,
  button,
  .button,
  .node-vr-listing.view-mode-full .rc-avail-ajax-wrapper .rc-item-quote-link {
    min-height: 0px !important;
    height: 35px !important;
  }

  //QUICK SEARCH TABS BG NEEDS PNG
  .tpl-fw .featured-tabs {
    position: absolute;
    margin: auto;
    left: 0px;
    right: 0px;
    width: 900px;
    top: 20px;
    //set to white-seventy.png for transparent white background instead of black
    background: url("../images/black-seventy.png");
    #views-exposed-form-vacation-rental-listings-page-quick-search {
      margin-bottom: 0px;
    }
    .auto.section-container > .section > .content {
      background-color: transparent;
      background: none;
    }
  }

  //BLOCK GRID
  .large-block-grid-2,
  .large-block-grid-3 {
    list-style: none;
    margin-left: 0px;

    li {
      position: relative;
      float: left;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .large-block-grid-3 {
    li {
      width: 33.3333%;
    }
    li:nth-of-type(3n+1) {
      padding-left: 0px;
      clear: both;
    }
    li:nth-of-type(3n) {
      padding-right: 0px;
    }
  }
  .row {
    min-width: 0px;
  }

  //PROPERTY DETAILS PAGE CLEAN UP
  .footer-wrapper {
    clear: both;
    background: $primary-color;

    .footer-top-wrapper {
      background: darken($primary-color, 5%);
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  .group-vr-full-tabs {
    width: 100%;
  }
  .node-vr-listing.view-mode-full .group-left {
    width: 65%;
    float: left;
  }
  .node-vr-listing.view-mode-full .group-right {
    width: 35%;
    float: right;
  }

  //PROPERTY DETAILS PAGE SEARCH
  .node-vr-listing.view-mode-full .rc-avail-ajax-wrapper .rc-core-item-avail-form .form-item.form-item-rcav-begin,
  .node-vr-listing.view-mode-full .rc-avail-ajax-wrapper .rc-core-item-avail-form .form-item.form-item-rcav-adult,
  .node-vr-listing.view-mode-full .rc-avail-ajax-wrapper .rc-core-item-avail-form .form-item.form-item-rcav-end,
  .node-vr-listing.view-mode-full .rc-avail-ajax-wrapper .rc-core-item-avail-form .form-item.form-item-rcav-child {
    width: 50%;
    float: left;
  }
  .node-vr-listing.view-mode-full .rc-avail-ajax-wrapper .rc-core-item-avail-form .form-item.form-item-rcav-begin,
  .node-vr-listing.view-mode-full .rc-avail-ajax-wrapper .rc-core-item-avail-form .form-item.form-item-rcav-adul {
    padding-left: 0px;
    padding-right: 8px;
  }
  .node-vr-listing.view-mode-full .rc-avail-ajax-wrapper .rc-core-item-avail-form .form-item.form-item-rcav-end,
  .node-vr-listing.view-mode-full .rc-avail-ajax-wrapper .rc-core-item-avail-form .form-item.form-item-rcav-child {
    padding-right: 0px;
    padding-left: 8px;
  }

  .node-vr-listing.view-mode-full .rc-avail-ajax-wrapper .rc-item-pricing .rc-item-price span {
    text-align: right;
    display: block;
    width: 100%;
    padding-bottom: 3px;

    &.rc-price {
      font-size: 30px;
    }
  }
  .rc-core-item-avail-form .description {
    top: -8px;
  }
  #node-vr-listing-full-group-vr-side-info {
    clear: both;
  }
  .node-vr-listing.view-mode-full .rc-avail-ajax-wrapper .rc-item-quote-link {
    width: 59%;
    display: block;
    height: 35px;
    background-color: $secondary-color;
    float: left;
    color: $white;
    line-height: 35px;

    &:hover, &:active {
      background-color: $tertiary-color;
    }
  }
  .node-vr-listing.view-mode-full .rc-avail-ajax-wrapper .rc-item-pricing .rc-item-price-buy {
    float: left;
    width: 40%;
  }

  //PROPERTY DETAIL SIDEBAR
  .node-vr-listing.view-mode-full .vrweb-driving-directions,
  .node-vr-listing.view-mode-full .group-vr-full-map .view-nearby-activities table {
    width: 100%;
    display: block;
  }
  .node-vr-listing.view-mode-full .group-header a, .node-vr-listing.view-mode-full .group-header .bt-leads-share-url-form-wrapper {
    font-size: 14px;
    margin-right: 10px;
    display: inline-block;
    width: auto;
    clear: none;
  }

  //GET RID OF BORDER ARROW IN SECTION TABS, NOT RECOGNIZED IN IE8
  .search-tabs, .featured-tabs, .group-vr-full-tabs {
    .section-container,
    .section-container.auto,
    .section-container.tabs {
      > section,
      > section.active {
        .title {
          border: none;
          padding: 0px;
          margin-bottom: 10px;

          a {
            width: 100%;
          }

          &:after {
            display: none;

          }
        }
      }
    }
  }

  //GET RID OF BORDER ARROW ON HOVER STATES, NOT RECOGNIZED IN IE8
  aside.sidebar-second h4.block-title:before,
  aside.sidebar-second section.block-views-c3ba3766bc704e2d345c300ce5a7a4d3:after,
  aside.sidebar-second ul.menu li:before, aside.sidebar-second .item-list ul li:before {
    display: none;
  }
  aside.sidebar-second ul.menu, aside.sidebar-second .item-list ul {
    margin-left: 25px;
  }
  aside.sidebar-second ul.menu li, aside.sidebar-second .item-list ul li {
    padding-left: 0px;
  }
  aside.sidebar-second ul.menu, aside.sidebar-second .item-list ul,
  aside.sidebar-second ul.menu li, aside.sidebar-second .item-list ul li {
    list-style: circle;
  }
  aside.sidebar-second {
    padding: 0px 15px 0px 0px !important;
    background: transparent !important;
    background-color: transparent !important;

    section {
      padding: 15px;
      background: $muted-color;
    }
  }
  &.page-blog,
  &.section-blog,
  &.page-things-to-do,
  &.section-things-to-do,
  &.page-events,
  &.section-events {
    aside.sidebar-second {
      section {
        padding: 0px !important;
        background: lighten($muted-color, 3%);
      }
    }
  }

  .row .row,
  .view-events.view-display-id-page .views-row .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  //VACATION RENTALS PAGE
  input[type="text"]#edit-rcav-begin, input[type="text"]#edit-rcav-end,
  input[type="text"]#edit-avail-filter-rcav-begin, input[type="text"]#edit-avail-filter-rcav-end {
    background-image: none;
  }
  .active-state-toggle,
  .toggle-style-link {
    width: 49%;
    display: block;
    height: 40px;
    line-height: 40px;
    color: #fff;
    clear: none;
    text-align: center;
    margin-bottom: 15px;
  }
  .active-state-toggle {
    background: $tertiary-color;
  }
  .toggle-style-link {
    background: $secondary-color;
  }
  &.search-results-page {
    .active-state-toggle {
      float: left;
    }
    .toggle-style-link {
      float: right;
    }
  }
  &.search-results-page.map-results-page {
    .active-state-toggle {
      float: right;
    }
    .toggle-style-link {
      float: left;
    }
  }
  .view-vacation-rental-listings {
    .view-content {
      article.row {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: solid 1px $muted-color;
        position: relative;

        .group-header {
          .rc-item-pricing .rc-price {
            margin-right: 8px;
          }
          .field-name-node-link {
            position: absolute;
            top: 5px;
            right: 15px;
            width: 150px;

            a {
              display: inline-block;
              background: $tertiary-color;
              height: 35px;
              padding: 0px;
              line-height: 35px;
              color: $black;
              width: 150px;
              text-align: center;
            }
          }
        }
        .group-right {
          .rc-lodging-detail {
            font-weight: bold;
            margin-bottom: 5px;
          }
        }
      }
    }
    .view-header {
      .views-widget-per-page.views-widget-per-page,
      .form-item-sort-by.form-item-sort-by {
        width: 25%;
        float: left;
        margin-right: 15px;
      }
      .search-summary {
        font-weight: bold;
        margin-top: 0px;
        margin-bottom: 15px;
      }
    }
  }
  #views-exposed-form-vacation-rental-listings-page-teasers #edit-field-vr-featured-amenities-tid-wrapper label[for="edit-field-vr-featured-amenities-tid"] {
    height: auto;
    margin-bottom: 10px;
    display: block;
  }

  //SET PAGE TITLE TO MAX WIDTH OF BODY
  h1#page-title {
    width: 1000px;
    margin: auto;
    float: none;
  }
  .l-main.row {
    max-width: 1000px;
    margin: auto;
  }
  aside.sidebar-second {
    padding-right: 15px;
  }

  //EVENTS, TTD, BLOG TEASER LAYOUTS TO MATCH VR SEARCH RESULTS
  .view-events.view-display-id-page,
  .view-things-to-do.view-display-id-page,
  .view-id-blog.view-display-id-page {
    .view-mode-teaser {
      position: relative;

      .field-name-node-link {
        position: absolute;
        top: 5px;
        right: 15px;
        width: 150px;

        a {
          display: inline-block;
          background: $tertiary-color;
          height: 35px;
          padding: 0px;
          line-height: 35px;
          color: $black;
          width: 150px;
          text-align: center;
        }
      }
    }
    .view-content {
      .views-row {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: solid 1px $muted-color;
      }
    }
  }
}

//HOMEPAGE FEATURED PROPERTY PANEL CLEAN UP 
body.front {
  .view-id-vacation_rental_listings.view-display-id-block_homeage_featured .view-content .group-content {
    padding: 4px;
  }
  .view-id-vacation_rental_listings.view-display-id-block_homeage_featured .view-content .group-content .field-name-title,
  .view-id-vacation_rental_listings.view-display-id-block_homeage_featured .view-content .group-content .rc-core-cat,
  .view-id-vacation_rental_listings.view-display-id-block_homeage_featured .view-content .group-content .rc-lodging-detail {
    padding-left: 5px;
  }

  .view-id-vacation_rental_listings.view-display-id-block_homeage_featured .view-content img {
    display: block;
    width: auto !important;
    height: auto;
    min-width: 0px;
    border: none;
  }
}

//CHECKOUT PAGE
.page-rescms-item-buy #edit-comments, .page-rescms-item-buy #edit-terms {
  float: none;
  margin-left: auto;
  margin-right: auto;
  clear: both;
}

.page-rescms-item-buy #edit-submit {
  clear: both;
}

.page-rescms-item-buy #edit-addresses-primary#edit-addresses-primary,
.page-rescms-item-buy #edit-addresses-billing,
.page-rescms-item-buy #edit-payment-cc {
  .form-item-addresses-billing-firstname,
  .form-item-addresses-billing-lastname,
  .form-item-addresses-primary-firstname,
  .form-item-addresses-primary-lastname,
  .form-item-addresses-primary-phone-1,
  .form-item-addresses-primary-phone-0,
  .form-item-addresses-billing-phone-0,
  .form-item-addresses-billing-phone-1 {
    width: 50%;
    float: left;
    position: relative;
    padding-right: 15px;
  }

  .form-item-addresses-primary-email,
  .form-item-addresses-primary-address-line1,
  .form-item-addresses-primary-address-line2,
  .form-item-addresses-primary-address-country,
  .form-item-addresses-billing-email,
  .form-item-addresses-billing-address-line1,
  .form-item-addresses-billing-address-line2,
  .form-item-addresses-billing-address-country,
  .form-item-payment-cc-cardname,
  .form-item-payment-cc-cardnum {
    width: 100%;
    position: relative;
    float: left;
    padding-right: 15px;
  }
  .form-item-addresses-primary-address-city,
  #primary-state-wrapper,
  .form-item-addresses-primary-address-postal,
  .form-item-addresses-billing-address-city,
  #billing-state-wrapper,
  .form-item-addresses-billing-address-postal,
  .form-item-payment-cc-cardtype,
  .form-item-payment-cc-cardmonth,
  .form-item-payment-cc-cardyear {
    width: 33.3333%;
    float: left;
    position: relative;
    padding-right: 15px;
  }
}








